@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-roboto antialiased;
  @apply text-black bg-gray-50/50;
  @apply dark:text-white dark:bg-gray-900;
}

.offline-mode {
  @apply overflow-hidden pointer-events-none;
}

/* Datapicker */
.react-datepicker {
  @apply text-sm;
  @apply bg-white dark:bg-slate-700;
  @apply rounded-lg drop-shadow shadow;
  @apply dark:ring-1 ring-gray-600;
  @apply overflow-hidden relative;
}

.react-datepicker__header {
  @apply text-center;
  @apply bg-gray-100/70 dark:bg-gray-800;
  @apply py-1.5;
  @apply relative;
  @apply border-b border-gray-200/50 dark:border-gray-600;
}

.react-datepicker__navigation {
  @apply absolute top-px overflow-hidden z-10;
  @apply w-8 h-8;
  @apply flex;
  @apply -indent-96;
  @apply before:absolute before:top-2;
  @apply before:border-t-[6px] before:border-t-black dark:before:border-t-white;
  @apply before:border-b-[6px] before:border-b-transparent;
  @apply before:border-x-[6px] before:border-x-transparent;
}

.react-datepicker__navigation--previous {
  @apply left-1;
  @apply before:rotate-90;
}

.react-datepicker__navigation--next {
  @apply right-1 justify-end;
  @apply before:-rotate-90;
}

.react-datepicker__month {
  @apply p-1.5;
}

.react-datepicker__current-month {
  @apply capitalize font-semibold;
}

.react-datepicker__week {
  @apply whitespace-nowrap;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  @apply text-center;
  @apply inline-flex justify-center items-center w-6 h-6 m-1;
}

.react-datepicker__day {
  @apply cursor-pointer rounded;
  @apply hover:bg-gray-100 hover:aria-[selected=false]:dark:bg-gray-600;
  @apply aria-disabled:opacity-20 aria-disabled:pointer-events-none;
  @apply aria-selected:bg-security aria-selected:text-white;
  @apply aria-[current=date]:font-semibold;
}

.react-datepicker__day-names {
  @apply capitalize;
}

.react-datepicker__day--weekend {
  @apply text-red-500;
}

.react-datepicker__children-container {
  @apply px-1.5 pb-1.5 space-y-1;
}

.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker-wrapper {
  @apply h-full;
}

.react-datepicker__tab-loop {
  @apply absolute z-10;
}

.react-datepicker__input-container {
  @apply flex items-center h-full;
}
